@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import "_reset";

html, body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
html {
  overflow-x: hidden;
}
body {
  min-height: 100%;
}
.pagecon {
  display: flex;
  min-height: 100vh;
  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
}
.mainside {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 220px;
  color: white;
  flex-shrink: 0;
  background: #0075be;
background: linear-gradient(to bottom, #0075be 0%, #0075be 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#0075be", endColorstr="#0075be",GradientType=0 );
  @media screen and (max-width: 760px) {
    width: 100%;
    box-sizing: border-box;
  }
  nav {
    margin-top: 55px;
    @media screen and (max-width: 760px) {
      display: none;
    }
    >ul {
      >li {
        box-sizing: border-box;
        height: 50px;
        line-height: 48px;
        padding: 0 28px;
        position: relative;
        font-size: 18px;
        font-weight: bold;
        a {
          display: block;
          &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 22px;
            height: 22px;
            margin-right: 16px;
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            filter: brightness(0) invert(1);
          }
        }
        &.das a::before {
          background-image: url("../img/ico-dash.svg");
        }
        &.exc a::before {
          background-image: url("../img/ico-exc.svg");
        }
        &.too a::before {
          background-image: url("../img/ico-tol.svg");
        }
        &.sli a::before {
          background-image: url("../img/ico-sli.svg");
        }
        &.sup a::before {
          background-image: url("../img/ico-sup.svg");
        }
        &.cli a::before {
          background-image: url("../img/ico-cli.svg");
        }
        &.sys a::before {
          background-image: url("../img/ico-sys.svg");
        }
        >ul {
          display: none;
        }
        &.active {
          background: #f2f8fd;
          color: #0075be;
          &::before, &::after {
            content: "";
            position: absolute;
            @media screen and (max-width: 760px) {
              display: none;
            }
          }
          &::before {
            top: -1;
            bottom: -1;
            border-top: 26px solid transparent;
            border-bottom: 25px solid transparent;
            border-left: 13px solid #0075be;
            left: 100%;
          }
          &::after {
            top: -0px;
            border-top: 25px solid transparent;
            border-bottom: 26px solid transparent;
            border-left: 13px solid #f2f8fd;
            left: calc(100% - 1px);
          }
          a::before {
            filter: brightness(.2);
          }
        }
      }
    }
  }
  .copy {
    margin-top: auto;
    padding: 0 28px 24px;
    @media screen and (max-width: 760px) {
      display: none;
    }
    p {
      line-height: 22px;
      font-size: 12px;
      a {
        text-decoration: underline;
      }
    }
  }
  .text-center {
  text-align:center;
  }
}
.sidehead {
  @media screen and (max-width: 760px) {
    height: 70px;
    position: relative;
  }
  .logo {
    padding: 26px 10px 26px 20px;
    @media screen and (max-width: 760px) {
      padding-top: 17px;
      padding-bottom: 0;
      padding-right: 0;
    }
    a {
      display: block;
      overflow: hidden;
      width: 168px;
      height: 60px;
      /*background: url("../img/logo.svg") no-repeat 50% 50%;*/
      background-size: contain;
      text-indent: -10000px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media screen and (max-width: 760px) {
        width: 104px;
        height: 37px;
      }
    }
  }
  .mob-handle {
    @media screen and (max-width: 760px) {
      position: absolute;
      display: block;
      width: 26px;
      height: 14px;
      top: calc(50% - 7px);
      right: 18px;
      span, span::before, span::after {
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background: white;
        content: "";
      }
      span {
        top: 0;
        &::before {
          top: 6px;
        }
        &::after {
          top: 12px;
        }
      }
    }
  }
}
.mobileuser {
  display: none;
}
.mainside {
  @media screen and (max-width: 760px) {
    &.open {
      min-height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 101;
      overflow-y: auto;
      .mobileuser {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        border-top: 1px solid #e9edf0;
        border-bottom: 1px solid #e9edf0;
        padding: 16px 0 4px;
        >ul {
          width: calc(50% - 8px);
        }
        .prop {
          .name {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 12px;
          }
        }
        .ucontrol {
          li {
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: bold;
            a {
              display: block;
              &::before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background-position: 50% 50%;
                background-size: contain;
                background-repeat: no-repeat;
                vertical-align: middle;
                margin-top: -2px;
                margin-right: 10px;
                filter: brightness(0) invert(1);
              }
            }
            &.logout {
              a::before {
                background-image: url("../img/ico-power.svg");
              }
            }
            &.profile {
              a::before {
                background-image: url("../img/ico-user.svg");
              }
            }
            &.password {
              a::before {
                background-image: url("../img/ico-lock.svg");
              }
            }
          }
        }
      }
      nav {
        display: block;
        margin-top: 40px;
        >ul>li.open {
          background: #f3f7fc;
          color: #00c2ac;
          >a::before {
            filter: brightness(0.2);
          }
          ul {
            display: block;
            color: white;
            padding: 10px 0;
            >li {
              padding-left: 40px;
              a {
                font-size: 14px;
                line-height: 32px;
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
.mpcontent {
  background: #f1f4f7;
  flex-grow: 1;
}
.pagehead {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sysmsg {
    max-width: 740px;
    padding: 14px 30px;
    display: flex;
    @media screen and (max-width: 760px) {
      flex-direction: column;
      padding-bottom: 40px;
    }
    position: relative;
    .msginfo {
      flex-shrink: 0;
      width: 154px;
      height: 82px;
      border-right: 1px solid #99aabb;
      padding-right: 16px;
      box-sizing: border-box;
      @media screen and (max-width: 760px) {
        width: 100%;
        border-color: transparent;
        height: auto;
      }
    }
    h2 {
      color: #0075be;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 20px;
      //max-width: 105px;
      @media screen and (max-width: 760px) {
        max-width: 60%;
      }
    }
    time {
      display: block;
      font-size: 12px;
      text-transform: uppercase;
      color: #9da1a6;
      margin: 6px 0;
      @media screen and (max-width: 760px) {
        position: absolute;
        top: 10px;
        right: 20px;
      }
    }
    .smsgctl {
      color: #616f7bff;
      font-weight: bold;
      font-style: italic;
      display: flex;
      align-items: center;
      @media screen and (max-width: 760px) {
        position: absolute;
        bottom: 10px;
        left: 30px;
      }
      >a {
        display: block;
        width: 5px;
        height: 8px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: 6px;
        &.prev {
          background-image: url("../img/arrowl1.svg");
        }
        &.next {
          background-image: url("../img/arrowr1.svg");
        }
        &.prev.disabled {
          background-image: url("../img/arrowl2.svg");
          cursor: default;
        }
        &.next.disabled {
          background-image: url("../img/arrowr2.svg");
          cursor: default;
        }
      }
    }
    .sysmsgc {
      margin-left: 20px;
      height: 82px;
      overflow-y: auto;
      @media screen and (max-width: 760px) {
        margin-left: 0;
        margin-top: 15px;
      }
      li {
        display: none;
        &.active {
          display: block;
        }
        line-height: 21px;
        color: #616f7b;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
  .uscontrol {
    width: 270px;
    height: 44px;
    display: flex;
    position: relative;
    justify-content: flex-end;
    margin-right: 14px;
    @media screen and (max-width: 760px) {
      display: none;
    }
    .msg {
      line-height: 20px;
      >span {
        display: block;
      }
      .msgc {
        font-size: 16px;
        font-weight: bold;
        color: #616f7b;
      }
      .account {
        color: #616f7b;
        font-size: 14px;
      }
    }
    .dropdown {
      visibility: hidden;
      z-index: 2;
      &.open {
        visibility: visible;
      }
      top: 44px;
      right: 0;
      min-width: 200px;
      background: white;
      position: absolute;
      padding: 12px 0 6px;
      >li {
        &::before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background-position: 50% 50%;
          background-size: contain;
          background-repeat: no-repeat;
          vertical-align: middle;
          margin-top: -2px;
          margin-right: 10px;
        }
        padding: 0px 20px;
        margin-bottom: 6px;
        &.uinfo {
          &::before {
            display: none;
          }
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #99aabb;
          >span {
            display: block;
          }
          .company {
            font-weight: normal;
          }
        }
        &.logout {
          padding-top: 10px;
          margin-top: 10px;
          border-top: 1px solid #99aabb;
          &::before {
            background-image: url("../img/ico-power.svg");
          }
        }
        &.profile {
          &::before {
            background-image: url("../img/ico-user.svg");
          }
        }
        &.password {
          &::before {
            background-image: url("../img/ico-lock.svg");
          }
        }
      }
      border: 1px solid #c2ccd6;
      border-radius: 8px;
      color: #616f7b;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
    }
    .ddcontrol {
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 11px;
      box-sizing: border-box;
      margin-left: 10px;
      border: 1px solid #99aabb;
      background: rgba(153,170,187,.23);
      margin-top: 4px;
      position: relative;
      &::before {
        content: "";
        width: 12px;
        height: 8px;
        position: absolute;
        top: calc(50% - 4px);
        left: calc(50% - 6px);
        background: url("../img/arrowd1.svg") no-repeat 50% 50%;
        background-size: contain;
      }
    }
  }
}

.subnav {
  height: 58px;
  position: relative;
  line-height: 54px;
  border-bottom: 22px solid #bac6d1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d9dfe6+0,eceff3+9,eceff3+100 */
background: rgb(217,223,230); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(217,223,230,1) 0%, rgba(236,239,243,1) 9%, rgba(236,239,243,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(217,223,230,1) 0%,rgba(236,239,243,1) 9%,rgba(236,239,243,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(217,223,230,1) 0%,rgba(236,239,243,1) 9%,rgba(236,239,243,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9dfe6', endColorstr='#eceff3',GradientType=0 ); /* IE6-9 */

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: #0075be;
    z-index: 0;
  }
  @media screen and (max-width: 760px) {
    display: none;
  }
  .single {
    color: #41566cff;
    font-size: 13px;
    font-weight: bold;
    padding-left: 30px;
  }
  >ul {
    color: #41566c;
    font-size: 13px;
    font-weight: bold;
    padding-left: 30px;
    display: flex;
    >li {
      position: relative;
      margin-right: 16px;
      flex-shrink: 0;
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        height: 3px;
        background: #00c2ac;
        content: "";
        display: none;
        bottom: 13px;
      }
      &:hover {
        &::after {
          display: block;
        }
      }
      &:hover ul {
        visibility: visible;
      }
      >ul {
        position: absolute;
        visibility: hidden;
        top: 42px;
        width: 150px;
        background: white;
        z-index: 1;
        left: calc(50% - 75px);
        border-radius: 8px;
        border: 1px solid #c0cbd5;
        padding: 5px 0;
        >li {
          text-align: center;
          a {
            display: block;
          }
          &:hover {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2ac1c5+0,18bc9c+100 */
background: rgb(42,193,197); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(42,193,197,1) 0%, rgba(24,188,156,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(42,193,197,1) 0%,rgba(24,188,156,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(42,193,197,1) 0%,rgba(24,188,156,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2ac1c5', endColorstr='#18bc9c',GradientType=1 ); /* IE6-9 */
            color: white;
          }
        }
      }
    }
  }
}
.content-tlist {
  margin: 30px;
  //overflow: hidden;
  border: 1px solid #bbc6d2;
  background: white;
  border-radius: 16px;
}
.content-table {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  @media screen and (max-width: 760px) {
    tbody {
      display: block;
      width: 100%;
    }
  }
  th, td {
    height: 46px;
    @media screen and (max-width: 760px) {
      display: flex;
      width: 100%;
      align-items: center;
    }
    div {
      max-width: 300px;
      //overflow: hidden;
      text-overflow: ellipsis;
/*       white-space: nowrap; */
    }
  }
  th:first-of-type, td:first-of-type {
    padding-left: 34px;
    @media screen and (max-width: 760px) {
      padding-left: 0;
    }
  }
  th:last-of-type, td:last-of-type {
    padding-right: 34px;
    max-width: 150px;
    @media screen and (max-width: 760px) {
      padding-right: 0;
      max-width: 100%;
      border-bottom: 1px solid #99aabb;
    }
  }
  th {
    color: #99aabb;
    @media screen and (max-width: 760px) {
      display: none;
    }
  }
  td {
    color: #616f7b;
    span {
      display: none;
      @media screen and (max-width: 760px) {
        display: block;
        width: 50%;
        color: #99aabb;
        padding-left: 16px;
        box-sizing: border-box;
      }
    }
  }
  tr:nth-of-type(2n) td {
    background: #f6f8f9;
  }
  @media screen and (max-width: 760px) {
    tr {
      display: block;
      &:last-of-type {
        td:last-of-type {
          border-color: transparent;
        }
      }
    }
  }
  a {
    color: #00c2ac;
  }
  em {
    display: inline-block;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    background: #67c23a;
    border-radius: 4px;
    color: white;
    min-width: 70px;
    text-align: center;
    &.c1 {
      background: #67c23a;
    }
    &.c2 {
      background: #ffa700;
    }
    &.c3 {
      background: #41566c;
    }
    @media screen and (max-width: 760px) {
      vertical-align: baseline;
      background: transparent;
      color: inherit;
      padding: 0;
    }
  }
  .fullw {
    width: calc(100% - 12px);
    box-sizing: border-box;
  }
}
html {
  height: 100%;
}
.fullh {
  height: 100%;
  /*position: relative;*/
}
.form-page {
  min-height: 100%;
  background: #ecf0f3;
  padding: 10px;
  box-sizing: border-box;
  &.dark {
    background: #2b2c38;
    .ff-cont {
      h2, p {
        color: white;
      }
    }
    .ff-logo {
    .system-status {
      position: fixed;
      right: 10px;
      bottom: 20px;
      width: 100px;
      text-align: center;
      color: white;
    }
    i {
      width: 65px;
      height: 65px;
      position: fixed;
      right: 20px;
      bottom: 60px;
      background: url("../img/plug-white.png") no-repeat 50% 50%;
      background-size: contain;
      @media screen and (max-width: 760px) {
        position: relative;
        bottom: 0;
        right: 0;
        margin: 20px auto;
        display: block;
      }
    }
    }
    &.d1, &.d2, &.d3 {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    &.d1 {
      background-image: url("../img/dbg1.jpg");
    }
    &.d2 {
      background-image: url("../img/dbg2.jpg");
    }
    &.d3 {
      background-image: url("../img/dbg3.jpg");
    }
  }
}
.form-box {
  max-width: 642px;
  box-sizing: border-box;
  margin: 80px auto 50px;
  background: #f7f9fa;
  padding: 40px 45px 35px;
  border-radius: 16px;
  border: 1px solid #c3ccd7;
  box-shadow: 0px 5px 7px rgba(0,0,0,.1);
  @media screen and (max-width: 760px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin-top: 30px;
  }
  &.login-form {
    max-width: 474px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 0;
  }
}
.reg-form {
  .logo {
    width: 220px;
    height: 78px;
/*     background: url("../img/logo-f.svg") 50% 50% no-repeat; */
/*     background-size: contain; */
    margin: 0 auto 44px;
    overflow: hidden;
/*     text-indent: -10000px; */
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }
  &.login-form {
    .logo {
      margin-bottom: 20px;
    }
    h2 {
      margin-bottom: 20px;
    }
  }
  h2 {
    font-size: 22px;
    text-transform: uppercase;
    font-style: italic;
    text-align: center;
    font-weight: bold;
    span {
      display: inline-block;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#29c1c5+0,18bc9c+100 */
      background: #000;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
     /* background: linear-gradient(to right, #008A00 0%, #00B100 100%);*/
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#008A00", endColorstr="#00B100",GradientType=1 );
      /* IE6-9 */
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
    margin-bottom: 12px;
  }
  h3 {
    color: #41566c;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    margin-bottom: 60px;
  }
}
.content-form {
  h4 {
    color: #616f7b;
    font-size: 16px;
    font-weight: bold;
    line-height: 34px;
    margin: 18px 0 26px;
    border-bottom: 1px solid #99aabb;
    span {
      color: #99aabb;
    }
  }
  >p {
    margin: 1em 0;
  }
  .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    >div {
      position: relative;
      height: 48px;
      margin-bottom: 18px;
      &.autoh {
        @media screen and (max-width: 760px) {
          height: auto;
        }
      }
      >label {
        pointer-events: none;
        overflow: hidden;
        position: absolute;
        height: 26px;
        line-height: 26px;
        top: 11px;
        left: 1px;
        transition: .3s;
        z-index: 1;
        font-size: 15px;
        color: #41566c;
        padding: 0 12px;
        em {
          color: #d92930;
        }
        &::before {
          z-index: -1;
          background: transparent;
          width: 400px;
          height: 24px;
          content: "";
          position: absolute;
          top: 13px;
          left: 0;
          border-radius: 24px 0 0 0;
        }
      }
      &.filled {
        label {
          font-size: 12px;
          top: -13px;
          left: 0;
          &::before {
            background: white;
          }
        }
      }
      input, .select {
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        border-radius: 24px;
        background: white;
        border: 1px solid #bec9d4;
        line-height: 46px;
        padding: 0 12px;
        font-size: 15px;
        color: #41566c;
        font-weight: bold;
        outline: none;
      }
      .select {
        position: relative;
        select {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 46px;
          opacity: 0;
        }
        &::before {
          content: "";
          width: 12px;
          height: 8px;
          position: absolute;
          top: calc(50% - 4px);
          right: 12px;
          background: url("../img/arrowd1.svg") no-repeat 50% 50%;
          background-size: contain;
        }
      }
    }
    &.form-split {
      >div {
        width: calc(50% - 18px);
        @media screen and (max-width: 760px) {
          width: 100%;
        }
      }
    }
    &.form-fullw {
      >div {
        width: 100%;
      }
    }
    &.form-center {
      justify-content: center;
      >div {
        width: 100%;
        max-width: 380px;
      }
    }
  }
  .txtc {
    color: #696969;
    text-align: center;
    font-size: 14px;
    margin: 20px 0;
  }
  .check {
    font-size: 14px;
    input[type=checkbox] {
      display: none;
    }
    input+span {
      display: inline-block;
      vertical-align: middle;
      width: 17px;
      height: 17px;
      box-sizing: border-box;
      border: 1px solid #99aabb;
      margin-right: 5px;
      margin-top: -1px;
      border-radius: 3px;
    }
    input:checked+span {
      background: url("../img/check.svg") no-repeat 50% 50%;
      background-size: 12px 12px;
    }
  }
  a {
    color: #139ada;
    text-decoration: underline;
  }
  .cmarks {
    display: flex;
    justify-content: center;
    margin: 0px 0 20px;
    @media screen and (max-width: 760px) {
      flex-wrap: wrap;
      li {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    li {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
  button {
    display: block;
  border: 0;
  outline: none;
  height: 46px;
  width: 100%;
  cursor: pointer;
  border-radius: 23px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#29c1c5+0,18bc9c+100 */
  background: #008A00;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #008A00 0%, #00B100 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#29c1c5", endColorstr="#18bc9c",GradientType=1 );
  /* IE6-9 */
  color: white;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
}
.form-foot {
  position: relative;
  .ff-cont {
    text-align: center;
    max-width: 642px;
    margin-left: auto;
    margin-right: auto;
    h2 {
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 20px;
      font-weight:bold;
    }
    p {
      margin-bottom: 12px;
      font-size: 14px;
      color: #fff;
      a {
        color: #139ada;
        text-decoration: underline;
      }
      &.spacer {
        margin-top: 20px;
      }
      &.em {
        font-size: 18px;
      }
    }
  }
  .ff-logo {
    .system-status {
      position: fixed;
      right: 10px;
      bottom: 20px;
      width: 100px;
      text-align: center;
      color: white;
    }
    i {
      width: 65px;
      height: 65px;
      position: fixed;
      right: 20px;
      bottom: 60px;
      background: url("../img/plug-white.png") no-repeat 50% 50%;
      background-size: contain;
      @media screen and (max-width: 760px) {
        position: relative;
        bottom: 0;
        right: 0;
        margin: 20px auto;
        display: block;
      }
    }
  }
}
.login-form .content-form .form-row.form-center > div {
  margin-bottom: 0;
}
@media screen and (max-width: 760px) {
  
}

.content-filters {
  .tsm-handle {
    display: none;
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 13px;
    font-weight: bold;
    background: #0480c6;
    color: white;
    min-width: 120px;
    text-align: center;
    line-height: 29px;
    @media screen and (max-width: 760px) {
      display: block;
      float: right;
    }
  }
  .cof-form {
    @media screen and (max-width: 760px) {
      display: none;
      position: fixed;
      overflow-y: auto;
      background: white;
      padding: 20px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      &.open {
        display: block;
      }
      .cof-close {
        position: absolute;
        top: 22px;
        right: 20px;
        width: 14px;
        height: 14px;
        cursor: pointer;
        &::before, &::after {
          width: 18px;
          height: 1px;
          top: 50%;
          transform-origin: 50% 50%;
          left: calc(50% - 9px);
          content: "";
          position: absolute;
          background: #616f7b;
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .cof-box {
    padding: 16px;
    margin: 15px 30px;
    background: #f8f9fb;
    border-radius: 16px;
  }
  .cof-row {
    display: flex;
    margin-top: 10px;
    @media screen and (max-width: 760px) {
      flex-wrap: wrap;
    }
  }
  .cof-field {
    margin-right: 16px;
    @media screen and (max-width: 760px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      &.mobinl {
        width: auto;
        margin-right: 16px;
      }
    }
    >input {
      box-sizing: border-box;
      width: 100%;
    }
  }
  .cof-multi {
    span {
      font-size: 13px;
      font-weight: bold;
      color: #768594;
      margin: 0 8px 0 4px;
    }

    &.flex {
      display: flex;
      align-items: center;
    }
  }

  h4 {
    color: #0480c6;
    font-size: 16px;
    margin-bottom: 10px;
    @media screen and (max-width: 760px) {
      line-height: 29px;
    }
  }
  h6 {
    display: none;
    color: #0275be;
    @media screen and (max-width: 760px) {
      display: block;
      padding-bottom: 14px;
      border-bottom: 1px solid #99aabb;
    }
  }
  label {
    font-weight: bold;
    font-size: 12px;
    color: #768594;
    margin-bottom: 4px;
    display: block;
  }
  input {
    -webkit-appearance: none;
    outline: none;
    border: 1px solid #99aabb;
    height: 29px;
    box-sizing: border-box;
    padding: 0 8px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: bold;
    color: #63717d;
    background: white;
    &.datepicker {
      width: 120px;
    }
  }
  select {
    display: block;
    font-size: 13px;
    font-weight: bold;
    color: #63717d;
    padding: 0 28px 0 8px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #99aabb;
    border-radius: 6px;
    height: 29px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;

    background-image: url("../img/arrowd1.svg");
    background-position: right .7em top 50%, 0 0;
    background-repeat: no-repeat;
    /* icon size, then gradient */
    background-size: .65em auto, 100%;
    
    &::-ms-expand {
        display: none;
    }
    &:hover {
        border-color: #888;
    }
    &:focus {
        border-color: #aaa;
        /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222; 
        outline: none;
    }
  }
  button {
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
    &.main {
      background: #0480c6;
      color: white;
      min-width: 120px;
    }
    &.second {
      background: transparent;
      color: #616f7b;
      border: 1px solid #616f7b;
      min-width: 100px;
    }
  }
}
.support-page {
  padding: 15px 30px;
  .support-split {
    display: flex;
    justify-content: space-between;
  }
  h2 {
    color: #0275be;
    font-size: 20px;
    font-weight: bold;
  }
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #0275be;
  }
  .s-side {
    flex-shrink: 0;
    width: 375px;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #cfd7df;
    background: #f8f9fb;
    border-radius: 16px;
    margin-left: 80px;
    color: #41566c;
    a {
      text-decoration: underline;
      color: #0480c6;
    }
    ul {
      li {
        position: relative;
        padding-left: 30px;
        margin-top: 27px;
        &::before {
          position: absolute;
          width: 20px;
          height: 20px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: contain;
          left: 0;
          top: 0px;
          content: "";
        }
        &.ticket::before {
          background-image: url("../img/ico-ticket.svg");
        }
        &.email::before {
          background-image: url("../img/ico-mail.svg");
        }
        &.chat::before {
          background-image: url("../img/ico-chat.svg");
        }
        &.phone::before {
          background-image: url("../img/ico-phone.svg");
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
  }
  .s-main {
    flex-grow: 1;
    >ul {
      margin-top: 32px;
      >li {
        padding-left: 35px;
        position: relative;
        margin-bottom: 12px;
        overflow: hidden;
        span {
          position: absolute;
          box-sizing: border-box;
          width: 22px;
          height: 22px;
          border-radius: 11px;
          border: 1px solid #0275be;
          left: 0;
          top: 0;
          &::before, &::after {
            width: 8px;
            height: 2px;
            top: calc(50% - 1px);
            left: calc(50% - 4px);
            content: "";
            position: absolute;
            background: #0275be;
            transform-origin: 50% 50%;
            transition: .3s;
          }
          &::after {
            transform: rotate(-90deg);
          }
        }
        &.open span::after {
          transform: rotate(0);
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #0275be;
          margin-bottom: 6px;
        }
        .content {
          line-height: 20px;
          padding-bottom: 0px;
          position: relative;
          color: #41566c;
          overflow: hidden;
          height: 0;
          box-sizing: border-box;
          &::after {
            content: "";
            background: #99aabb;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 104px;
            opacity: 0;
          }
        }
        &.open {
          .content {
            height: auto;
            padding-bottom: 17px;
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.popup {
  position: fixed;
  visibility: hidden;
  &.open {
    visibility: visible;
  }
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(65,86,108,.9);
  .pop-inner {
    margin: auto;
    padding: 20px;
    max-width: 700px;
    width: 100%;
  }
  .pop-box {
    max-width: 700px;
    box-sizing: border-box;
    background: white;
    padding: 30px;
    border-radius: 15px;
    width: 100%;
    position: relative;
  }
  .close {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    background: url("../img/pop-close.svg") no-repeat 50% 50%;
    background-size: contain;
    right: 35px;
    top: 26px;
    cursor: pointer;
  }
  h2 {
    color: #0275be;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 12px;
    margin-bottom: 24px;
    border-bottom: 1px solid #99aabb;
  }
  .pf-row {
    margin-bottom: 24px;
    &.split {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      >div {
        width: calc(50% - 10px);
        @media screen and (max-width: 760px) {
          width: 100%;
          margin-bottom: 24px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    &.buttons {
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      .pop-btn {
        display: block;
        width: 192px;
        height: 43px;
        box-sizing: border-box;
        text-align: center;
        line-height: 41px;
        border: 1px solid #616f7b;
        border-radius: 22px;
        color: #616f7b;
        font-weight: bold;
        font-size: 14px;
        background: transparent;
        cursor: pointer;
      }
      button.pop-btn {
        margin-left: 16px;
        background: #0480c6;
        border-color: transparent;
        color: white;
      }
    }
  }
  .pf-field {
    label {
      display: block;
      color: #758594;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }
    input {
      -webkit-appearance: none;
      outline: none;
      border: 1px solid #99aabb;
      color: #758594;
      font-weight: bold;
      font-size: 14px;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 37px;
      padding: 0 8px;
      border-radius: 8px;
    }
    select {
      display: block;
      font-size: 13px;
      font-weight: bold;
      color: #63717d;
      padding: 0 28px 0 8px;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      margin: 0;
      border: 1px solid #99aabb;
      border-radius: 6px;
      height: 37px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;

      background-image: url("../img/arrowd1.svg");
      background-position: right .7em top 50%, 0 0;
      background-repeat: no-repeat;
      /* icon size, then gradient */
      background-size: .65em auto, 100%;

      &::-ms-expand {
        display: none;
      }
      &:hover {
        /*border-color: #888;*/
      }
      &:focus {
        border-color: #aaa;
        /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        outline: none;
      }
    }
  }

  .api-pane {
    color: #758594;
    font-weight: bold;
    font-size: 14px;
    word-wrap: break-word;
  }
}

#statusPopup {
  .pf-field {
    width: 100%;
    text-align: center;
  }
}

button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
  margin: 0 5px;
  padding: 0 5px;
}
.offset{
  width: 500px !important;
  margin: 20px auto;
}

.pagination {
  display: flex;
  justify-content: center;

  li {
    margin: 0 5px;
  }
}

.pagination-section {
  display: flex;
  justify-content: space-between;
  margin: 30px;
}


.notifications {
  position: fixed;
  right: 50%;
  top: 0;
  transform: translateX(50%);
  visibility: hidden;
  z-index: 9999;
}
.notifications.visible {
  visibility: visible;
}
.notifications li {
  margin-top: 10px;
  opacity: 1;
  transition: .5s;
  min-width: 300px;
  max-width: 600px;
  width: 80%;
  padding: 5px 10px;
  box-sizing: border-box;
  line-height: 20px;
  border-radius: 3px;  
  text-align: center;
  color: white;
  border: 1px solid rgba(255,255,255,0.3);
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  animation-name: notify-in;
  animation-duration: .5s;
}
@keyframes "notify-in" {
  from {
    margin-top: -30px;
    opacity: 0;
  }
  to {
    margin-top: 10px;
    opacity: 1;
  }
}
.notifications li.dismissed {
  opacity: 0;
  margin-top: -30px;
}
.notifications li.success {
  background-color: #f0f9eb;
  color: #67c23a;
}
.notifications li.warn {
  background-color: #fdf6ec;
  color: #e6a23c;
}
.notifications li.error {
  background-color: #fef0f0;
  color: #f56c6c;
}

.status-page {
  margin: 40px auto;
  max-width: 800px;
  h1 {
    margin-bottom: 20px;
    font-size: 1.5em;
  }
}

.full-w  {
  width: 100%
}

.datetime-picker__button {
  width: 150px;
}

.error {
  .datetime-picker__button {
    border: 1px solid red;
  }
}

@import "node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker";
